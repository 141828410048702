import Select from 'react-select'
import {useEffect, useState} from "react";


function SearchBar({selectedWardId, setSelectedWardId, data}) {
    const [assembly, setAssembly] = useState('')

    const assemblyOptions = [...new Map(data.sort((a, b) => a.ac_name.localeCompare(b.ac_name)).map(({ac_no, ac_name}) => {
        return {
            value: ac_no,
            label: ac_name,
        }
    }).map(item =>
        [item['value'], item])).values()]

    const wardOptions = data.filter(({ac_no}) => ac_no === assembly).map(({ward_no, ward_name}) => {
        return {
            value: ward_no,
            label: `${ward_name}(${ward_no})`,
        }
    })

    useEffect(() => {
        setSelectedWardId('')
    }, [assembly])

    const selectedWard = selectedWardId ? wardOptions.find(({value}) => value === selectedWardId) : null

    return (
        <>
            <Select className={'print-hide'} placeholder={'Select Assembly'} onChange={({value}) => setAssembly(value)} options={assemblyOptions} />
            <Select className={'print-hide'} value={selectedWard} placeholder={'Select Ward'} onChange={({value}) => setSelectedWardId(value)} options={wardOptions} />
        </>
    );
}

export default SearchBar;