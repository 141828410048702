import {Table} from "react-bootstrap";

export default function (props) {
    console.log({"Table2Col": props})
    const {
        body
    } = props
    return(
        <Table bordered hover>
            <thead>
            <tr>
                <th>Type</th> <th>Percentage</th>
            </tr>
            </thead>
            {Object.entries(body)
                .map(([key, value]) => {
                    if(!value || value?.trim()?.length === 0){
                        return null
                    }
                    return (
                        <tbody>
                        <tr>
                            <td>{key}</td>
                            <td>{value}</td>
                        </tr>
                        </tbody>
                    )
                }).filter((x) => !!x)}

        </Table>
    );
}