import {Col, Container, Row} from "react-bootstrap";

export default function (props) {
    return(
        <Container>
            <Row>
                {
                    props.data.map((obj) =>
                        <Col sm={6} md={4} lg={3}><strong style={{textTransform:'uppercase'}}>{obj.key}:</strong> {obj.value}</Col>
                    )
                }
            </Row>
        </Container>
    );
}