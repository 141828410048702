import {Col, Container, Row} from "react-bootstrap";

import SingeRowGrid from "./SingeRowGrid";
import Table2Col from "./Table2Col";
import Table3Col from "./Table3Col";
import SearchBar from "./SearchBar";


function WardDetails({wardDetails}) {

    if(!wardDetails){
        return (
            <h2>Please select ward from dropdown at top</h2>
        )
    }

    const {
        religion_stats,
        ac_name,
        ward_name,
        ward_no,
        reservation_status,
        total_voters,
        no_of_booths,
        no_of_polling_stations,
        overall_summary,
        habitat_stats,
        caste_stats,
        aap_candidate,
        sc_voters,
    } = wardDetails

    const indexToSplit = Math.ceil(caste_stats.length/2);

    function getReligionData() {
        const arr = Object.entries(religion_stats).map(([key, value]) => {
            console.log({"map": key})
            return {"key": key, "value": value}
        })
        console.log({"getReligionData": arr})
        return arr
    }

    return (
        <>
            <Container>
                <Row>
                    <Col><h3>Assembly: {ac_name}</h3></Col>
                    <Col><h3>Ward: {ward_name}({ward_no})</h3></Col>
                    <Col><h3>Reservation: {reservation_status}</h3></Col>
                    <Col><h3>Candidate: {aap_candidate}</h3></Col>
                </Row>
            </Container>

            <h5>{"Ward Details"} </h5>
            <SingeRowGrid data={[
                {"key": "Total Voters", "value": total_voters},
                {"key": "SC Population", "value": sc_voters},
                {"key": "Total No. of Booths", "value": no_of_booths},
                {"key": "Total Polling Stations", "value": no_of_polling_stations},
            ]}/>
            <hr />

            <p>{overall_summary}</p>

            <hr />

            <h5>{"Religion Status"} </h5>
            <SingeRowGrid data={getReligionData()} />
            <hr />

            <h5>{"Habitat Status"} </h5>
            <Table2Col body={habitat_stats} />

            <h5>{"Caste Status"} </h5>
            <Row>
                <Col sm={6}><Table3Col body={caste_stats.slice(0, indexToSplit)} /></Col>
                <Col sm={6}><Table3Col body={caste_stats.slice(indexToSplit)} /></Col>
            </Row>

            {/*<h5>{"AAP Probable Candidates"} </h5>*/}
            {/*<Table3Col body={aap_probable_candidates} />*/}

            {/*<h5>{"BJP Probable Candidates"} </h5>*/}
            {/*<Table3Col body={bjp_probable_candidates} />*/}

            {/*<h5>{"INC Probable Candidates"} </h5>*/}
            {/*<Table3Col body={inc_probable_candidates} />*/}
        </>
    );
}

export default WardDetails;
