import {Table} from "react-bootstrap";

export default function (props) {
    const {
        body
    } = props

    const stripEmptyData = (data) => data.filter((bodyItem) => {
        return !Object.values(bodyItem).every(value => !value);
    })

    return(
        <Table striped bordered hover>
            <thead>

            {/*Renders table header*/}
            <tr>
                {Object.entries(body[0]).map(([key, value]) => <th style={{'textTransform': 'uppercase', 'width': '50%'}}>{key}</th>)}
            </tr>

            {/*  */}
            </thead>
            {stripEmptyData(body).map(bodyItem => {
                return (
                    <tbody>
                    <tr>
                        {
                            Object.entries(bodyItem).map(([key, value]) => {
                                return (<td>{value}</td>)
                            })
                        }
                    </tr>
                    </tbody>
                )
            })}

        </Table>
    );
}