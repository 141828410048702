import preval from 'preval.macro'
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'
import all_wards_data from './data/all_wards_data.json'
import WardDetails from "./components/WardDetails";
import {Container} from "react-bootstrap";
import SearchBar from "./components/SearchBar";
import {useState} from "react";
import useTitle from "./hooks/useTitle";

function App() {
    const buildDate = preval`module.exports = new Date();`
    const [selectedWardId, setSelectedWardId] = useState('')
    const wardDetails = selectedWardId ? all_wards_data.find(({ward_no}) => ward_no === selectedWardId) : null

    const title = selectedWardId ? `Ward ${selectedWardId}` : `Please select a ward`

    useTitle(`MCD 2022 ${title}`)

    return (
        <>
            <Container>
                <SearchBar data={all_wards_data} selectedWardId={selectedWardId} setSelectedWardId={setSelectedWardId} />
                <WardDetails wardDetails={wardDetails} />
            </Container>
            <div className={'print-hide'}>
                Build Date: {new Date(buildDate).toLocaleString()}
            </div>
        </>
    )
}

export default App